#nprogress {
    .spinner-icon {
        border-top-color: var(--secondary);
        border-left-color: var(--secondary);
    }

    .bar {
        background: var(--secondary) !important;
    }
}

.progress-wrap svg.progress-circle path {
    stroke: var(--secondary);
}

.progress-wrap:after {
    color: var(--secondary);
}

.dropdown-menu:not(.mega-menu) {
    left: -1.4rem !important;
}
