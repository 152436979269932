.user-card-container {
    cursor: pointer;

    &.card-box-expand {
        z-index: 10;
    }

    &:hover .card-inner {
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
        border-color: #356db6b2;
    }
    .card-inner {
        border: 5px solid transparent;
        transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
        min-height: 400px;
        backface-visibility: hidden;
        .profile-avatar {
            height: 100% !important;
            padding-bottom: 0;
            object-fit: cover;
            aspect-ratio: 1/1;
            object-position: 0% 0%;
        }
    }
    .card-flip-back {
        backface-visibility: hidden;
        transform: rotateY(180deg);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        max-height: 100%;
        cursor: pointer;

        @media screen and (min-width: 992px) {
            &.card-expand .card-body {
                margin: -10% -20%;
            }
        }
        .card-body {
            transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            background-color: #072c5c;

            overflow: auto;
            &::-webkit-scrollbar-track {
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;

                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar {
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;
                width: 6px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;
                background-color: #acacac;
            }
        }
    }
}

.user-card-modal {
    .user-info-markdown {
        p:last-child {
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: 992px) {
    .user-card-border.border-start {
        border: none !important;
    }
}

@media screen and (max-width: 1400px) {
    .user-card-container {
        .card-inner {
            min-height: 500px;
        }
    }
}
