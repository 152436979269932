@import 'constant';

// 992px
@media (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    .site-navbar.navbar.center-nav {
        .offcanvas-nav {
            background: initial !important;
            box-shadow: unset !important;
        }
    }

    .hero-slider-wrapper {
        height: calc(100vh - var(--navbar-height) - var(--topbar-height));

        .hero-slider,
        .owl-stage-outer,
        .owl-stage,
        .owl-item,
        .owl-slide {
            height: 100% !important;
        }
    }

    .navbar-nav > li > ul.mega-menu .mega-menu-content {
        padding: 0.6rem 0 0.2rem;
    }

    .pdf-item,
    .youtube-item,
    .video-item {
        .modal-dialog {
            max-width: 90vw;
        }
    }
    .pdf-item {
        .modal-dialog {
            max-width: 80vw;
        }
    }

    .youtube-item,
    .video-item {
        .modal-dialog {
            max-width: 55vw;
        }
    }

    .site-navbar.navbar.fixed .navbar-nav > .nav-item > .dropdown-menu,
    .site-navbar.navbar:not(.transparent)
        .navbar-nav
        > .nav-item:not(.language-select)
        > .dropdown-menu {
        margin-top: 0 !important;
    }
    #home-slide {
        .section-join-us {
            display: none;
        }
    }
}
