:root {
    --primary: #072c5c;
    --primaryLight: #626b82;
    --primaryDark: #0f1a2c;
    --textOnPrimary: #ffffff;
    --secondary: #ffc107;
    --secondaryLight: #fff350;
    --secondaryDark: #c79100;
    --textOnSecondary: #000000;

    --navbar-height: 68px;
    --topbar-height: 35px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--primary);
        font-family: 'IBM Plex Serif', Helvetica Neue, Arial, sans-serif;
    }
}

html {
    scroll-behavior: smooth;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    font-family: 'IBM Plex Sans', Helvetica Neue, Arial, sans-serif;
}

h1,
h2,
h3 {
    text-shadow: 0px 1px 2px rgb(0 0 0 / 55%);
}
