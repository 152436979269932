.content-markdown-wrapper,
.content-html-wrapper {
    p {
        margin-bottom: 0;
    }

    p + p {
        margin-top: 0.5rem;
    }

    div + div {
        p {
            margin-top: 0.5rem;
        }
    }

    ul {
        margin-bottom: 0.25rem;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        &:not(:first-child) {
            margin-top: 1rem;
        }

        display: block;
        margin-bottom: 0.75rem;
    }

    h1:not(.no-underline):after,
    h2:not(.no-underline):after,
    h3:not(.no-underline):after {
        position: absolute;
        bottom: -0.25rem;
        left: 0;
        width: 100px;
        height: 2px;
        content: '';
        background-color: var(--secondary);
        margin-left: 0;
    }

    .content + .content-text {
        margin-top: 1rem;
    }

    p > a {
        text-decoration: underline;
    }

    img.border {
        padding: 5px;
        background-color: #efefef;
        border-radius: 3px;
        margin-top: 0.5rem;
        display: block;
    }
}

.home-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
    .heading-title {
    }
    .sub-title {
    }

    &.home-slide {
        padding: unset;
    }

    &:nth-child(odd) {
        background-color: white;
        box-shadow: 0rem 0rem 1.25rem rgb(30 34 40 / 4%) !important;
    }
}

// Hot fixes: Need class to identify each content-type

.post-date {
    opacity: 0;
}

.header-subnav {
    align-self: center;
    font-size: 0.9em;
}

// Button action
.action-wrap {
    position: fixed;
    z-index: 10;
    bottom: 5rem;
    right: 0;
    background-color: #000;
    color: #fff;
    padding: 14px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    min-width: 140px;
    text-transform: uppercase;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
        rgba(0, 0, 0, 0.22) 0px 10px 10px;
    .action-title {
        margin-bottom: 0;
        padding-bottom: 12px;
        line-height: 1;
        font-weight: 500;
    }
    .action-btn {
        margin: 0 auto;
        min-width: 100%;
        padding: 4px 6px;
        height: 35px;
        display: grid;
        place-content: center;
        grid-template-columns: repeat(2, auto);
        grid-gap: 4px;
        margin-bottom: 6px;
    }
}
.custom-spin {
    display: inline-block;
    animation: spin 1s ease-in-out infinite forwards;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
        transform-origin: 50% 46%;
    }
    to {
        transform: rotate(360deg);
        transform-origin: 50% 46%;
    }
}

.content-wrapper .cms-edit-btn {
    display: grid;
    place-content: center;
    border-radius: 50%;
    line-height: 1;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
    text-transform: capitalize;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
            rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }
}

// cms-edit-wrap
.cms-edit-wrap {
    position: absolute;
    right: 0;
    top: 0;
    .edit-btn {
        padding: 0;
        line-height: 1;
        padding: 0.2rem;
        min-width: 2rem;
        text-align: center;
        border: none;
        border-radius: 4px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                rgba(0, 0, 0, 0.23) 0px 3px 6px;
        }
    }
}
