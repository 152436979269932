@import "constant";

// 768px
@media (min-width: #{map-get($grid-breakpoints, 'md')}) {
    #page-section {
        .container {
            max-width: calc(100vw / 1.2);
        }
    }

    .home-section {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
    }

    .popup-title {
        font-size: 1.5rem;
        font-weight: 500;
    }
}
