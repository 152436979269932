@import './base';

#__next {
    overflow: auto;
}

.text-reset,
.text-inverse {
    a.hover {
        &:hover {
            color: var(--secondary) !important;
        }
    }
}

header.image-wrapper:not(.mobile) {
    background-attachment: scroll !important;
}

header.image-wrapper,
.navbar {
    z-index: 999 !important;
}

.navbar .hamburger,
.navbar [data-toggle='offcanvas-info'],
.navbar .btn {
    pointer-events: none;
}

.navbar .container {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.thumbnail {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.carousel-box-overlay {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(80, 93, 99, 0.5);
    color: white;
}

.video-block {
    li:before {
        display: none;
    }
}

.dropdown-item {
    &:hover {
        background: #e0e9fa;
    }
}

#tecqpartners-navbar {
    background-color: var(--primary);
    height: var(--navbar-height);
}

//hero section

#hero_header {
    position: relative;
    background-color: black;
    height: 60vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
    background-size: cover;
}

#hero_header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

#hero_header .container {
    position: relative;
    z-index: 2;
}

#hero_header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.4;
    z-index: 1;
}

#footer-custom {
    background: var(--primary);
}

.header {
    color: var(--secondary);
}

h1:not(.no-underline),
h2:not(.no-underline),
h3:not(.no-underline) {
    position: relative;
    font-weight: 500;

    &::after {
        position: absolute;
        bottom: -0.5rem;
        left: 50%;
        width: 100px;
        margin-left: -50px;
        height: 3px;
        content: '';
        background-color: var(--secondary);
    }
}

#howwehelp {
    h3#title-md {
        &::after {
            margin-left: unset;
            left: 0;
        }
    }

    .how-we-help-list {
        div {
            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}

.site-navbar.navbar:not(.banner--unstick),
.navbar.banner--stick:not(.navbar-dark),
.navbar.banner--stick.transparent {
    box-shadow: 0px 0px 1em rgba(80, 93, 99, 0.5) !important;
}

.dropdown-menu {
    border: 1px solid #ccc !important;
    box-shadow: 0px 0px 1em rgba(80, 93, 99, 0.5) !important;
    padding: 0.5rem 0 !important;
}

.site-navbar.navbar {
    .offcanvas-nav {
        background: #335c99 !important;
        box-shadow: 2px 0px 4px #333;
    }

    .mega-menu-content {
        .dropdown-header-title {
            position: relative;
            text-shadow: 0px 1px 2px rgb(0 0 0 / 55%);
            font-size: 1.1em;
            margin-bottom: 0.25rem;

            &::after {
                position: absolute;
                bottom: -0.25rem;
                left: 0;
                width: 100px;
                height: 2px;
                content: '';
                background-color: var(--secondary);
            }
        }
    }

    .navbar-nav.sm-collapsible {
        .dropdown-header-title {
            color: white;
            padding-top: 0.25rem;
        }
    }
}

.site-navbar.navbar {
    .navbar-brand {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .navbar-nav:not(.sm-collapsible) > li > a {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .navbar-nav:not(.sm-collapsible) > li.show > a,
    .navbar-nav:not(.sm-collapsible) > li > a:hover {
        color: var(--primary);
        background-color: white;

        .sub-arrow {
            color: var(--primary);
        }
    }
}

.site-navbar.navbar.banner--stick {
    height: unset !important;

    .navbar-brand {
        img {
            width: 250px;
            padding: 0;
        }
    }
}

.site-navbar.navbar {
    .navbar-brand {
        img {
            width: 300px;
            height: 100%;
        }
    }
}

@media (max-width: 991.98px) {
    .dropdown-menu.mega-menu {
        .dropdown-item {
            line-height: 1.5;
        }
    }

    .navbar-nav .dropdown-menu {
        border: none !important;
        box-shadow: none !important;
        padding: 0 !important;
    }

    .navbar-nav .nav-link,
    .navbar-nav .dropdown-menu .dropdown-item {
        &:hover {
            background: transparent;
            text-decoration: underline;

            .sub-arrow {
                color: var(--secondary);
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 576px) {
    .pdf-item,
    .youtube-item,
    .video-item {
        .modal-dialog {
            max-width: 100vw;
            margin: 0;
        }
    }
}

.dropdown-menu .dropdown-item {
    &:hover {
        background: #e0e9fa;
    }
}

.navbar-nav[class*='collapsible'] .dropdown-menu .dropdown-item {
    &:hover {
        background: transparent;
        text-decoration: underline;
    }
}

.post-thumbnail,
.advantage .post-item {
    &:not(.no-transform):hover {
        section {
            transform: scale(1.1);
            transition: all 0.5s;
        }
    }

    .post-title {
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// slide

#home-slide {
    h2 {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 3.5rem;
        white-space: 2px;
        font-family: 'IBM Plex Serif', sans-serif;
        display: inline-block;
    }

    p,
    h2 {
        color: #fff;
        text-shadow: 2px 2px 3px #333333, 0 0 15px #545354;
    }
}

// end slide

h4.widget-title {
    border-color: var(--secondary) !important;
}

.text-inverse a.hover {
    border-color: var(--secondary) !important;
}

.card-hover {
    &:hover {
        box-shadow: 0rem 0.25rem 1.75rem rgb(30 34 40 / 7%) !important;
    }
}

.page-description {
    p {
        margin-bottom: 0 !important;
    }
}

#category-page {
    .post-header {
        h1 {
            display: block;
            width: min-content;
            white-space: nowrap;
        }
    }

    .inner-page {
        h3 {
            &::after {
                display: none;
            }
        }
    }
}

.category-header-title {
    &::after {
        left: 0 !important;
        margin-left: 0 !important;
    }
}

// TODO: Advantage

#advantage {
    .post-content {
        p {
            margin-bottom: unset;
        }

        .line-clamp {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

.advantage {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.zooming-container {
    &.advantage-active {
        .card {
            position: absolute;
            width: 120%;
            left: -10%;
            top: -5%;
            box-shadow: 0 0 0 0.05rem rgb(8 60 130 / 6%),
                0rem 0rem 0.75rem rgb(30 34 40 / 45%) !important;
            z-index: 1;
        }
    }
}

@media (max-width: 769px) {
    .zooming-container {
        cursor: unset;
    }
    #home-slide {
        &.hero-slider-wrapper {
            min-height: 590px !important;
        }

        .hero-slider .owl-slide {
            height: 590px !important;
        }
    }
}

.about-us-page-customize {
    height: unset !important;
}

.hidden-heading {
    h2 {
        display: none !important;
    }
}

.owl-carousel-homepage {
    transition: all 700ms ease;
}

/* TODO: markdown content styles */

#markdown-wrapper {
    h1,
    h2,
    h3 {
        &::after {
            display: none;
        }
    }

    p {
        margin-bottom: 0 !important;
    }
}

.liststyle-none {
    &::before {
        display: none !important;
    }
}

#markdown-wrapper img {
    width: 100%;
    border: 1px solid #eee;
}

.markdown-content-wrapper {
    &.slide-markdown-wrapper {
        p {
            color: white;
        }
    }
}

/* TODO: --- end markdown content styles */

#dropdown-basic {
    padding: unset;
    transform: none;
    color: white;
    margin: unset;
    border: none;
    transition: all 200ms ease-in-out;
    // line-height: unset;

    text-decoration-style: solid;

    &:after {
        display: none;
    }

    .text-hover {
        position: relative;

        &::before {
            position: absolute;
            width: 100%;
            height: 1px;
            background: white;
            top: 20px;
            left: 0;
            pointer-events: none;
            content: '';
            transform-origin: 100% 50%;
            transform: scale3d(0, 1, 1);
            transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
        }
    }

    &:hover {
        .text-hover {
            &::before {
                transform-origin: 0% 50%;
                transform: scale3d(1, 1, 1);
                transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
            }
        }
    }
}

#topbar {
    // height: var(--topbar-height);

    #icon-down {
        line-height: 0;
        height: 0;
        padding: 0;
        margin: 0;
    }
}

.topbar-dropdown-menu {
    margin-top: -1px;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    overflow: hidden;
    border-radius: 0.4rem;
    font-size: 0.75rem;
}

.sub-navbar-popover-overlay .popover-body {
    padding: 0.5rem;
}

.popover {
    box-shadow: 0 0.25rem 1.75rem rgba(30, 34, 40, 0.07);
    max-width: unset;
    word-wrap: normal;
    top: 5px !important;
}

.relative-wrap {
    position: relative;
}

// UI/UX improvement
.nav-link.has-submenu:active,
.nav-link.has-submenu:focus {
    text-decoration: none;
}
.nav-link > .nav-link-icon {
    display: inline-block;
    transform: translate(0.3rem, -0.11rem);
}
.nav-link > .nav-link-icon svg {
    transition: all 0.4s ease-in-out;
}
.nav-item:hover .nav-link-icon svg {
    fill: #335c99;
}
.header-subnav {
    align-self: center;
}
#tecqpartners-navbar .navbar-collapse {
    .sub-arrow {
        display: none;
    }
    .offcanvas-header {
        padding: 30px 0;
    }
}
.image-background-overlay {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #072c5c18;
        z-index: -1;
    }
}

@media screen and (max-width: 991px) {
    .nav-link.has-submenu.show {
        .nav-link-icon svg {
            fill: var(--secondary);
        }
    }
    .nav-item:hover .nav-link-icon svg {
        fill: #fff;
    }

    #tecqpartners-navbar .navbar-collapse {
        .sub-arrow {
            display: none;
        }
        .nav-link.has-submenu {
            position: relative;
            & .nav-link-icon {
                content: '';
                position: absolute;
                right: 1.4rem;
            }
        }
        .dropdown-header {
            margin-top: 6px;
        }
        .nav-link-icon {
            margin-left: auto;
            transform: translate(4px, -4px);
        }
        .mega-menu-content {
            padding-left: 0px;
            position: relative;
            padding-top: 5px;
            padding-bottom: 15px;
            line-height: 1.5;
            font-size: 0.9em;
            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 90%;
                height: 1px;
                background-color: rgba(211, 211, 211, 0.45);
                left: 0;
            }
            &::before {
                top: 0;
            }
            &::after {
                bottom: 0;
            }
        }
        .mega-menu .row {
            margin: 0;
        }
        .mega-menu .col-sm {
            flex: 1 0 100%;
        }
        .col-sm,
        .dropdown-header,
        .dropdown-item {
            padding-left: 0;
            padding-right: 0;
        }
    }
    #home-slide {
        .owl-dots {
            bottom: 110px;
        }
        .section-join-us {
            bottom: 30px;
            left: 0;
            right: 0;
            z-index: 2;
            .join-us-button {
                background-color: #ffde5a;
                color: #072c5c;
                border: 1px solid #002f8e60;
            }
        }
    }
}
// End UI/UX improvement
@media screen and (max-width: 576px) {
    #about-us-page .container .row {
        margin-top: 2rem !important;
        & > div > .row {
            margin-top: 0 !important;
        }
    }
    .content-html-wrapper {
        & table:last-child {
            width: 100%;
            & tr {
                display: flex;
                flex-direction: column;
            }
            & tr td {
                width: 100% !important;
            }
            & tr td:first-child::after {
                content: ':';
                color: var(--secondary);
                padding-left: 2px;
            }

            & tr td:last-child {
                padding-left: 25px;
            }
        }
    }
}
@media screen and (max-width: 425px) {
    .hero-slider-wrapper {
        & .owl-item .container {
            padding: 2.2rem 2.3rem !important;
            & .row {
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .hero-slider-wrapper {
        & .owl-item .container {
            padding: 2.1rem 2rem !important;
        }
    }

    .content-html-wrapper {
        word-break: break-word;
        & table {
            width: 100%;
            & tr {
                display: flex;
                flex-direction: column;
            }
            & tr td {
                width: 100% !important;
            }
            & tr td:first-child::after {
                content: ':';
                color: var(--secondary);
                padding-left: 2px;
            }

            & tr td:last-child {
                padding-left: 25px;
            }
        }
    }
    #footer-custom {
        & .container {
            word-break: break-word;
        }
    }
}
@media screen and (max-width: 360px) {
    .navbar-brand img {
        width: 100%;
        max-width: 100%;
    }
    .hero-slider-wrapper {
        & .owl-item .container {
            padding: 2rem 2.3em !important;
            font-size: 0.9em;
        }
        & .owl-item .container .slide-markdown-wrapper {
            font-size: 1.1rem !important;
        }
    }
}

@media screen and (max-width: 320px) {
    #about-us-page {
        & .container table tr td:last-child {
            padding-left: 14px;
        }
        & .container table em.uil {
            display: none;
        }
        & > .container > div {
            margin: 0;
            margin-top: 2rem !important;
            & .col-lg-6 {
                padding: 0;
            }
        }
        & > .container > div > div {
            margin: 0 auto;
            flex: 1 0 100%;
        }
    }
    .hero-slider-wrapper {
        & .owl-item .container {
            padding: 1.2rem 1.6em !important;
            font-size: 0.85em;
        }
        & .owl-item .container .slide-markdown-wrapper {
            font-size: 1.1rem !important;
        }
    }
}
